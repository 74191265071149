<template>
    <div class="infoTable">
        <div class="handle-btn">
            <!-- <el-button type="primary" size="small" @click="sureSelect">
                确定
            </el-button> -->
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
            >
                >
                <el-table-column
                    label="选择"
                    width="55"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row"
                            @change.native="getTemplateRow(scope.$index,scope.row)"
                        >
                            <i></i>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项名称"
                >
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项编码"
                >
                    <template slot-scope="scope">
                        {{ scope.row.code }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项类型"
                >
                    <template slot-scope="scope">
                        <el-select
                            v-model="scope.row.info_type"
                            placeholder="请选择信息项类型"
                            disabled
                        >
                            <el-option label="普通" :value="0"></el-option>
                            <el-option label="复合" :value="1"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    label="信息项说明"
                >
                    <template slot-scope="scope">
                        {{ scope.row.remarks }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="显示顺序"
                >
                    <template slot-scope="scope">
                        {{ scope.row.in_entity_order }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: null, // 多选选中数据集合
            radio: '', // 单选
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        // 获取信息项
        getEntityInfo() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/info/edit/getpage/' + this.extr.entityId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getEntityInfo();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getEntityInfo();
        },
        // 单选事件
        getTemplateRow() {
            this.selectData = this.radio;
            if (this.selectData) {
                this.extr.closeCall(this.selectData);
                window.hideToast();
            } else {
                this.$message.warning('请选择信息项！');
            }
        },
        // 确定选择
        sureSelect() {
            if (this.selectData.length > 0) {
                this.extr.closeCall(this.selectData);
                window.hideToast();
            } else {
                this.$message.warning('请选择信息项！');
            }
        },
    },
    created() {
        if (!this.extr.entityId) {
            return;
        }
        this.getEntityInfo();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.infoTable
    padding .1rem
    height 100%
    .handle-btn
        margin-bottom .1rem
        text-align left
    .table-con
        height calc(100% - 52px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>